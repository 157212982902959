import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FaNewspaper, FaUsers, FaBullseye, FaFileAlt } from 'react-icons/fa';

const FormContainer = styled.div`
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  padding: 25px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(210, 176, 121, 0.2);

  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #D2B079;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.3;
  min-height: 36px;
  overflow-y: hidden;
  resize: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Hier fügen wir den Schatten hinzu

  &:focus {
    outline: none;
    border-color: #C0A068;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(210, 176, 121, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 12px;
    min-height: 32px;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  background-color: #D2B079;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: #C0A068;
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 16px;
  }
`;

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
`;

const LoaderText = styled.p`
  margin-top: 15px;
  font-size: 14px;
  color: #333;
  text-align: center;
`;

const Loader = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #D2B079;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${rotate} 1s linear infinite;
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
  color: #D2B079;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.h2`
  font-size: 18px;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  font-weight: normal;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const IconWrapper = styled.span`
  margin-right: 10px;
  color: #D2B079;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  font-weight: 600;
`;

const ExampleText = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  margin-bottom: 0;
  font-style: italic;
`;

const ExamplesContainer = styled.div`
  margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const API_BASE_URL = 'https://api.newsletter-ideen.de'

function Form() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    topic: '',
    targetAudience: '',
    goal: '',
    existingContent: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('Form submitted with data:', formData);
    
    try {
      const response = await fetch(`${API_BASE_URL}/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Add this line
        body: JSON.stringify(formData),
      
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Full server response:', data);

      // Erstellen und Loggen der URL für generierte Ideen
      const ideasUrl = `https://newsletter-ideen.de/ideas/${data.userId}`;
    console.log('URL für generierte Ideen:', ideasUrl);

      // Weiterleitung zum Quentn-Formular mit der generierten userId
    navigate(`/quentn-form?userId=${data.userId}`);
  } catch (error) {
    console.error('Error submitting form:', error);
      // Hier könnten Sie eine Fehlermeldung für den Benutzer anzeigen
    } finally {
      setIsLoading(false);
    }
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    const newHeight = element.scrollHeight;
    element.style.height = `${newHeight}px`;
  };

  const handleTextareaChange = (e) => {
    handleChange(e);
    adjustTextareaHeight(e.target);
  };

  useEffect(() => {
    // Passt die Höhe aller Textareas beim ersten Rendern an
    document.querySelectorAll('textarea').forEach(adjustTextareaHeight);
  }, []);

  return (
    <FormContainer>
      <Title>Newsletter-Ideen Generator</Title>
      <Subtitle>
        Generiere <BoldText>50 fesselnde Newsletter-Ideen</BoldText> in nur 60 Sekunden – Maximiere deine Öffnungsraten und Conversions
      </Subtitle>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
          <LoaderText>Dieser Prozess kann einige Sekunden dauern...</LoaderText>
        </LoaderContainer>
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <InputGroup>
            <LabelContainer>
              <IconWrapper><FaNewspaper /></IconWrapper>
              <Label htmlFor="topic">Was ist das Hauptthema deines Newsletters?</Label>
            </LabelContainer>
            <StyledTextarea
              id="topic"
              name="topic"
              value={formData.topic}
              onChange={handleTextareaChange}
              required
            />
            <ExamplesContainer>
              <ExampleText>
                Beispiel 1: „Fitness-Tipps für Anfänger, um in Form zu kommen und gesund zu bleiben."
              </ExampleText>
              <ExampleText>
                Beispiel 2: „Digitale Marketing-Strategien für kleine Unternehmen, um ihre Online-Präsenz zu verbessern."
              </ExampleText>
            </ExamplesContainer>
          </InputGroup>
          <InputGroup>
            <LabelContainer>
              <IconWrapper><FaUsers /></IconWrapper>
              <Label htmlFor="targetAudience">Für welche Zielgruppe ist der Newsletter gedacht?</Label>
            </LabelContainer>
            <StyledTextarea
              id="targetAudience"
              name="targetAudience"
              value={formData.targetAudience}
              onChange={handleTextareaChange}
              required
            />
            <ExamplesContainer>
              <ExampleText>
                Beispiel 1: „Menschen im Alter von 25-40 Jahren, die neu im Fitnessbereich sind und nach einfachen, effektiven Wegen suchen, um mit dem Training zu beginnen."
              </ExampleText>
              <ExampleText>
                Beispiel 2: „Kleine und mittelständische Unternehmen, die ihre Sichtbarkeit im Internet erhöhen und mehr Kunden über digitale Kanäle erreichen möchten."
              </ExampleText>
            </ExamplesContainer>
          </InputGroup>
          <InputGroup>
            <LabelContainer>
              <IconWrapper><FaBullseye /></IconWrapper>
              <Label htmlFor="goal">Was möchtest du mit dem Newsletter erreichen?</Label>
            </LabelContainer>
            <StyledTextarea
              id="goal"
              name="goal"
              value={formData.goal}
              onChange={handleTextareaChange}
              required
            />
            <ExamplesContainer>
              <ExampleText>
                Beispiel 1: „Ich möchte mehr Abonnenten in meinen Online-Shop führen und sie dazu inspirieren, bewusste Kaufentscheidungen zu treffen."
              </ExampleText>
              <ExampleText>
                Beispiel 2: „Ich möchte die Abonnenten dazu bringen, meine Marketingberatung in Anspruch zu nehmen oder mein digitales Kursangebot zu buchen."
              </ExampleText>
            </ExamplesContainer>
          </InputGroup>
          <InputGroup>
            <LabelContainer>
              <IconWrapper><FaFileAlt /></IconWrapper>
              <Label htmlFor="existingContent">Hast du bereits Inhalte oder Ressourcen, die du im Newsletter einbeziehen möchtest?</Label>
            </LabelContainer>
            <StyledTextarea
              id="existingContent"
              name="existingContent"
              value={formData.existingContent}
              onChange={handleTextareaChange}
            />
            <ExamplesContainer>
              <ExampleText>
                Beispiel 1: „Ich habe ein paar YouTube-Videos mit grundlegenden Übungen sowie Blog-Posts über die Bedeutung von Ausdauertraining."
              </ExampleText>
              <ExampleText>
                Beispiel 2: „Ich habe Blog-Posts über SEO und Social-Media-Marketing sowie ein E-Book mit Tipps zur Optimierung von Google Ads-Kampagnen."
              </ExampleText>
            </ExamplesContainer>
          </InputGroup>
          <ButtonContainer>
            <StyledButton type="submit" disabled={isLoading}>
              {isLoading ? 'Ideen werden generiert...' : 'IDEEN JETZT GENERIEREN'}
            </StyledButton>
          </ButtonContainer>
        </StyledForm>
      )}
    </FormContainer>
  );
}

export default Form;