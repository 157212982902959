import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const API_BASE_URL = 'https://api.newsletter-ideen.de';

const IdeasContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const Title = styled.h1`
  color: #D2B079;
  text-align: center;
  margin-bottom: 30px;
`;

const IdeaList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const IdeaItem = styled.li`
  background-color: white;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: flex-start;

  &:hover {
    transform: translateY(-3px);
  }
`;

const IdeaArrow = styled.span`
  color: #D2B079;
  margin-right: 10px;
  font-weight: bold;
`;

const IdeaText = styled.span`
  flex: 1;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  text-align: center;
`;

const CopyButton = styled.button`
  background-color: #D2B079;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #C0A068;
  }
`;

const SingleCopyButton = styled.button`
  background-color: #D2B079;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #C0A068;
  }
`;

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
`;

const CopyNotification = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #D2B079;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  animation: ${fadeInOut} 2s ease-in-out;
`;

const CheckIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

function IdeasDisplay() {
  const [ideas, setIdeas] = useState([]);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false); 
  const { userId, name } = useParams();

  useEffect(() => {
    const fetchIdeas = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/ideas/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch ideas');
        }
        const data = await response.json();
        setIdeas(data.ideas);
      } catch (error) {
        console.error('Error fetching ideas:', error);
        setError('Keine Ideen gefunden oder ungültige User ID.');
      }
    };

    if (userId) {
      fetchIdeas();
    }
  }, [userId]);

  const capitalizedName = name 
  ? decodeURIComponent(name)
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  : '';

  const showCopyNotification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  const copyAllIdeas = () => {
    const allIdeas = ideas.join('\n');
    navigator.clipboard.writeText(allIdeas).then(() => {
      showCopyNotification();
    }).catch(err => {
      console.error('Fehler beim Kopieren: ', err);
      alert('Fehler beim Kopieren der Ideen. Bitte versuchen Sie es erneut.');
    });
  };

  const copySingleIdea = (idea) => {
    navigator.clipboard.writeText(idea).then(() => {
      showCopyNotification();
    }).catch(err => {
      console.error('Fehler beim Kopieren: ', err);
      alert('Fehler beim Kopieren der Idee. Bitte versuchen Sie es erneut.');
    });
  };

  if (error) {
    return (
      <IdeasContainer>
        <ErrorMessage>{error}</ErrorMessage>
      </IdeasContainer>
    );
  }

  return (
    <IdeasContainer>
      <Title>Hey {capitalizedName}, hier sind Deine 50 genialen Newsletter Ideen</Title>
      <CopyButton onClick={copyAllIdeas}>Alle Ideen kopieren</CopyButton>
      <IdeaList>
        {ideas.map((idea, index) => (
          <IdeaItem key={index}>
            <IdeaArrow>➤</IdeaArrow>
            <IdeaText>{idea}</IdeaText>
            <SingleCopyButton onClick={() => copySingleIdea(idea)}>Kopieren</SingleCopyButton>
          </IdeaItem>
        ))}
      </IdeaList>
      {showNotification && (
        <CopyNotification>
          <CheckIcon>✓</CheckIcon>
          Ideen in die Zwischenablage kopiert
        </CopyNotification>
      )}
    </IdeasContainer>
  );
}

export default IdeasDisplay;