import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Form from './components/Form';
import QuentnForm from './components/QuentnForm';
import IdeasDisplay from './components/IdeasDisplay';
import Footer from './components/Footer';


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding-bottom: 150px; // Mindestens 100px Abstand zum Footer plus 50px Footer-Höhe
`;

function App() {
  return (
    <Router>
      <AppContainer>
        <ContentContainer>
          <Routes>
            <Route path="/" element={<Form />} />
            <Route path="/quentn-form" element={<QuentnForm />} />
            <Route path="/ideas/:userId/:name" element={<IdeasDisplay />} />
          </Routes>
        </ContentContainer>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
