import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const QuentnFormContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledButton = styled.button`
  padding: 10px;
  background-color: #D2B079;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #C0A068;
  }
`;

function QuentnForm() {
  const [userId, setUserId] = useState('');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('userId');
    if (id) {
      setUserId(id);
    }
  }, [location]);

  return (
    <QuentnFormContainer>
      <h2>Wir senden Dir die Ideen direkt per E-Mail zu.</h2>
      <StyledForm action="https://sabinekaluscha.eu-3.quentn-site.com/public/forms/3827/raw/submit" method="post">
        <InputGroup>
          <StyledLabel>Vorname</StyledLabel>
          <StyledInput type="text" name="first_name" required />
        </InputGroup>
        <InputGroup>
          <StyledLabel>E-Mail</StyledLabel>
          <StyledInput type="email" name="mail" required />
        </InputGroup>
        <StyledInput 
          type="hidden" 
          name="field_userid" 
          value={userId}
        />
        <StyledButton type="submit">Anmelden</StyledButton>
      </StyledForm>
    </QuentnFormContainer>
  );
}

export default QuentnForm;