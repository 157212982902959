import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: #666;
  background-color: #f9f9f9;
  height: 50px; // Feste Höhe für den Footer
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterLink = styled.a`
  color: #666;
  text-decoration: none;
  margin: 0 10px;
  &:hover {
    text-decoration: underline;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterLink href="https://sabinekaluscha.com/impressum" target="_blank" rel="noopener noreferrer">
        Impressum
      </FooterLink>
      |
      <FooterLink href="https://sabinekaluscha.com/datenschutz" target="_blank" rel="noopener noreferrer">
        Datenschutz
      </FooterLink>
    </FooterContainer>
  );
}

export default Footer;